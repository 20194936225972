import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";
import Api from "js/core/api";
import { setOverrideIdToken } from "js/core/utilities/overrideIdToken";
import * as config from "js/config";
import { withFirebaseAuth } from "js/react/views/Auth/FirebaseAuthContext";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { Presentation, PresentationPrivacyType } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import { User } from "js/core/models/user";
import ErrorMessage from "js/app/ErrorMessage";
import { IntegratorController } from "common/integrators/IntegratorController";
import { prepareToUnlockAudio } from "js/core/utilities/audioUtilities";
import { PresentationActivityType } from "common/interfaces";
import { presentations as presentationsApi } from "apis/callables";

import { getAnalytics, AnalyticsContextType } from "js/react/views/Player/helpers/analytics";
import PlayerContext from "js/react/views/Player/Context/PlayerContext";
import { BEAUTIFUL_WORKSPACE_ID } from "common/constants";
import { RemoveSplashScreen } from "js/editor/SplashScreen";
import LinkPasswordDialog from "js/react/components/Dialogs/LinkPasswordDialog";
import EmailSubmitDialog from "js/react/components/Dialogs/EmailSubmitDialog";
import { ShowDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import NotificationsService from "js/core/services/notifications";

import RequestAccessDialog, { RequestAccessDialogContextType } from "js/react/views/RequestAccessDialog/RequestAccessDialog";
import { identify } from "js/analytics";

const logger = getLogger(LogGroup.PLAYER);

const AuthContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #F8FAFC;
`;

class PlayerAppContext extends PlayerContext {
    constructor() {
        super();

        this.state = {
            status: "loading",
            errorMessage: null
        };
    }

    componentDidMount() {
        window.isPlayer = true;

        if (app.isConstrained) {
            window.document.body.classList.add("is_mobile");
        }

        IntegratorController.initApp()
            .then(() => this.load());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.status === "loading" && this.state.status === "loaded") {
            const name = this.contextData.presentation?.get("name");
            if (name) {
                document.title = "Beautiful.ai - " + this.contextData.presentation?.get("name");
            }
        }
    }

    componentWillUnmount() {
        window.isPlayer = false;

        if (app.isConstrained) {
            window.document.body.classList.remove("is_mobile");
        }

        setOverrideIdToken(null);
        document.title = "Beautiful.ai";

        this.dispose();
    }

    handleCopyDeck = async ({ presentation, analytics, isLoggedIn }) => {
        if (!isLoggedIn) {
            window.location = `/copyDeck/${presentation.id}?source=player`;
            return;
        }

        try {
            const { url } = await presentationsApi.copyPresentation({
                id: presentation.id,
                workspaceId: localStorage.getItem(BEAUTIFUL_WORKSPACE_ID)
            });

            analytics.trackUsePresentationAsTemplate();

            window.location = url;
        } catch (err) {
            logger.error(err, "PlayerAppContext handleCopyDeck() failed", { presentationId: presentation?.id });
        }
    }

    async load() {
        const { auth, firebaseUser, presentationOrLinkId } = this.props;

        this.setState({ status: "loading" });

        try {
            if (config.requirePlayerPassword) {
                const idToken = await ShowDialogAsync(LinkPasswordDialog, {
                    linkId: presentationOrLinkId
                });

                if (idToken) {
                    setOverrideIdToken(idToken);
                }
            }

            const {
                presentation: presentationData,
                showBranding,
                isCreatorOldBasicUser,
                showGetDeckUI,
                canEditPresentation,
                creator,
                isCollaborator,
                slug,
                slidesMetadata
            } = await Api.playerContext.get({ id: presentationOrLinkId });

            const isEmbedded = window.self !== window.top && !window.Cypress && !window.forceEmbedded && !app.isConstrained;

            if (!presentationData.public || presentationData.secured) {
                history.replaceState({}, `Beautiful.ai - ${presentationData.name}`, `/player/${presentationOrLinkId}/${slug}`);
            }

            if (!app.user && firebaseUser) {
                // Sign into all Firebase app instances
                await db.updateCurrentUser(firebaseUser);

                // Load the User model
                app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
                if (!ds.hasBeenSetup) {
                    ds.dummySetup();
                }
                await app.user.load();
                await identify(app.user.getAuthUser());
            } else if (!ds.hasBeenSetup) {
                ds.dummySetup();
            }

            // Initialize dictionary
            if (!app.dictionary) {
                app.dictionary = {
                    check: () => true
                };
            }

            // Load presentation model
            const presentation = new Presentation({ ...presentationData }, { disconnected: true });

            // Set selection
            ds.selection.presentation = presentation;
            ds.selection.presentation.slidesMetadata = slidesMetadata;

            // Note: slides won't be preloaded, the player will take care of loading them when needed
            const slides = presentation.getSips()
                .map(slideId => new Slide({ id: slideId }, { presentation, autoLoad: false, autoSync: false }))
                .reduce((slides, slide) => ({ ...slides, [slide.id]: slide }), {});

            let linkId = null;
            if (presentation.has("link")) {
                linkId = presentation.get("link").id;
            }

            const requireEmail = (
                presentation.get("requireEmail") &&
                presentation.getPrivacySetting() !== PresentationPrivacyType.PRIVATE
            );

            const allowPdfDownload = (
                presentation.get("allowPdfDownload") &&
                presentation.getPrivacySetting() !== PresentationPrivacyType.PRIVATE
            );

            const allowSwitchPresentation = (
                !!app.integrator &&
                canEditPresentation
            );

            const allowEditPresentation = (
                !this.contextData.isMobileOrTablet &&
                canEditPresentation
            );

            const allowSocialSharing = (
                presentation.get("showSocialSharing") &&
                presentation.getPrivacySetting() === PresentationPrivacyType.PUBLIC
            );

            const analytics = getAnalytics(AnalyticsContextType.APP, isEmbedded, firebaseUser, isCollaborator, presentation);
            const isLoggedIn = firebaseUser && !firebaseUser.isAnonymous;

            let username;
            if (isLoggedIn) {
                username = firebaseUser?.email;
            } else {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if (params.viewer) {
                    username = params.viewer;
                }
            }

            this.contextData = {
                ...this.contextData,
                isLoggedIn,
                presentation,
                slides,
                slidesMetadata,
                showBranding,
                isCreatorOldBasicUser,
                requireEmail,
                username,
                allowCopyLink: true,
                onCopyDeck: showGetDeckUI && (() => this.handleCopyDeck({ presentation, analytics, isLoggedIn })),
                allowSocialSharing,
                onSwitchPresentation: allowSwitchPresentation && (() => app.integrator.showPresentationList()),
                onEditPresentation: allowEditPresentation && (slideIndex => window.open(`/${presentation.id}/${slideIndex + 1}`, "_blank")),
                onDownloadPdf: allowPdfDownload && (username => presentation.downloadExportCache(
                    "pdf",
                    {
                        destination: "local",
                    },
                    () => {
                        analytics.trackDownloadPdf({ username });
                        presentationsApi.recordActivity({
                            id: presentation.id,
                            activity: PresentationActivityType.PDF_DOWNLOAD,
                            publicActivity: true,
                        });

                        // If the user is anonymous, we can't notify them
                        if (!username) return;

                        NotificationsService.notifyOnPlayerDownload(linkId, username, "pdf")
                            .catch(err => logger.error(err, "[AppContext] NotificationsService.notifyOnPlayerDownload() failed"));
                    },
                )),
                creator,
                isPresenterAvailable: !isEmbedded && canEditPresentation,
                presenterUrl: "/presenter",
                presenterWindowTarget: "_blank",
                analytics,
            };

            // Load theme
            await app.themeManager.loadTheme(this.contextData.presentation);

            // Preload data necessary for synchronous audio playback unlocking
            await prepareToUnlockAudio();

            // Check if we need and email/username
            if (this.contextData.requireEmail && !this.contextData.username) {
                ShowDialog(EmailSubmitDialog, {
                    onSuccess: username => {
                        this.contextData.username = username;
                        this.setState({ status: "loaded" });
                    },
                    closeDialog: () => {
                        // If the user needs to insert email to view the presentation, we need to perform an action
                        // so the view can "refresh", otherwise the layout will be broken because of the keyboard being open
                        // for the email input

                        // We have it on Playerview, but adding it just in case

                        window.scrollTo(0, 0);
                    }
                });
                return;
            }

            this.setState({ status: "loaded" });
        } catch (err) {
            if ([403, 401].includes(err.status)) {
                if (firebaseUser) {
                    ShowDialog(RequestAccessDialog, {
                        presentationOrLinkId,
                        contextType: RequestAccessDialogContextType.PLAYER
                    });
                } else {
                    const unsubscribe = auth.onAuthStateChanged(user => {
                        if (user) {
                            unsubscribe();
                            this.load();
                        }
                    });

                    this.setState({ status: "auth" });
                }
                return;
            }

            if (window.parent !== window) {
                // We cannot access the parent on a crossorigin domain, so we try/catch to detect this.
                try {
                    const baiIntegrator = window.parent.baiIntegrator;
                    if (baiIntegrator) {
                        baiIntegrator.clearState();
                    }
                } catch (err) {
                    logger.error(err, "PlayerAppContext baiIntegrator.clearState() failed", { presentationOrLinkId });
                }
            }

            logger.error(err, "PlayerAppContext load() failed", { presentationOrLinkId });

            this.setState({
                status: "error",
                errorMessage: err.message
            });
        }
    }

    dispose() {
        if (!this.contextData) {
            return;
        }

        // Reset selection
        ds.selection.presentation = null;
        ds.selection.slide = null;

        // Disconnecting all slides
        Object.values(this.contextData.slides).forEach(slide => slide.disconnect());

        // NOTE: we won't disconnect the presentation because it's been instantiated disconnected
    }

    render() {
        const { status, errorMessage } = this.state;

        if (status === "loading") {
            return null;
        }

        if (status === "auth") {
            return <AuthContainer><Authenticate defaultPage="signIn" /></AuthContainer>;
        }

        if (status === "error") {
            RemoveSplashScreen();
            return <ErrorMessage message={errorMessage} />;
        }

        return this.renderChildrenWithContext();
    }
}

export default withFirebaseAuth(withFirebaseUser(withRouter(PlayerAppContext)));
