import React from "react";

import { LoadingPage } from "js/react/components/LoadingPage";

import PresenterView from "./Components/PresenterView";

class Presenter extends React.Component {
    constructor() {
        super();

        this.state = {
            presenterProps: null
        };
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent("presenterDidMount", {
            detail: {
                presenter: this,
            }
        }));

        this.viewRef = React.createRef();
    }

    setPresenterProps = props => new Promise(resolve => {
        this.setState(({ presenterProps }) => ({ presenterProps: { ...(presenterProps ?? {}), ...props } }), () => resolve());
    });

    advancePresenter = (offsetFromCurrentSlide, suppressAnimation = false) => {
        this.viewRef?.current?.advancePresenter(offsetFromCurrentSlide, suppressAnimation);
    }

    render() {
        const { presenterProps } = this.state;

        if (!presenterProps) {
            return <LoadingPage />;
        }

        return (
            <PresenterView
                ref={this.viewRef}
                {...presenterProps}
            />
        );
    }
}

export default Presenter;
